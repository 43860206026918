<template>
  <div>
      <b-form>
          <b-row class="mb-1">
              <b-col>
                  <h2>
                      Busca la finca de carbono a visualizar
                  </h2>
              </b-col>
          </b-row>
          <b-row>
              <b-col md="2" xl="2" class="mb-1">
                  <b-form-group label="Nombre Finca" label-for="basicInput">
                      <b-form-input v-model="farmName" placeholder="Digite Nombre de la Finca"></b-form-input>
                  </b-form-group>
              </b-col>
              <b-col md="2" xl="2" class="mb-1">
                  <b-form-group label="Nombres Productor" label-for="basicInput">
                      <b-form-input v-model="firstName" placeholder="Digite Nombres"></b-form-input>
                  </b-form-group>
              </b-col>
              <b-col md="2" xl="2" class="mb-1">
                  <b-form-group label="Apellidos Productor" label-for="basicInput">
                      <b-form-input v-model="lastName" placeholder="Digite Apellidos"></b-form-input>
                  </b-form-group>
              </b-col>
              <b-col md="2" xl="2" class="mt-2">
                  <b-button variant="primary" @click="getAllCarbonSales">
                      <feather-icon icon="SearchIcon" class="mr-50" />Filtrar
                  </b-button>
              </b-col>
              <b-col md="2" xl="2" class="mt-2 text-right" offset-lg="2">
                  <b-button variant="warning" @click="addNewSale" block>
                      <feather-icon icon="PlusIcon" class="mr-50" />Añadir Venta
                  </b-button>
              </b-col>
          </b-row>
      </b-form>

      <b-card-code title="Ventas de créditos de carbono" no-body>

      <b-table
          ref="selectableTable"
          :fields="fields"
          :items="carbonSales"
          responsive
          selectable
          class="mb-0"
          :select-mode="selectMode"
          @row-selected="onRowSelected">
              <template #cell(farmName)="data">
                  {{ (data.item.farmName) }}
              </template>

              <template #cell(productorName)="data">
                  {{ (data.item.firstName) }} {{ (data.item.lastName) }}
              </template>

              <template #cell(saleDate)="data">
                  {{ (data.item.saleDate.slice(0, 10)) }}
              </template>

              <template #cell(amountCredits)="data">
                  {{ (data.item.amountCredits) }}
              </template>

              <template #cell(valuePerCredit)="data">
                  $ {{parseFloat(data.item.valuePerCredit).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}}
              </template>

              <template #cell(certificationCost)="data">
                  $ {{parseFloat(data.item.certificationCost).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}}
              </template>

              <template #cell(auditingCost)="data">
                  $ {{parseFloat(data.item.auditingCost).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}}
              </template>

              <template #cell(transactionCost)="data">
                  $ {{parseFloat(data.item.transactionCost).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}}
              </template>

              <template #cell(managementCost)="data">
                  $ {{parseFloat(data.item.managementCost).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}}
              </template>

              <template #cell(monitoringCost)="data">
                  $ {{parseFloat(data.item.monitoringCost).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}}
              </template>
      </b-table>
      </b-card-code>

      <b-row v-if="this.carbonSales.length <= 0" class="justify-content-center mt-3" style="text-align:center">
          <b-col style="justify-content:center" xl="4" md="6" lg="4">
              <b-alert variant="primary" show>
                  <div class="alert-body">
                      <span><strong>No Hay Información para mostrar</strong></span>
                  </div>
              </b-alert>
          </b-col>
      </b-row>

      <b-modal centered ref="buttons-carbon-sale-modal" hide-footer title="¿Que deseas hacer?">
          <b-button class="mt-2" variant="primary" block @click="editCarbonSale">
              <feather-icon icon="EditIcon" class="mr-50" />
              <span class="align-middle">Editar Venta</span>
          </b-button>
          <b-button class="mt-2" variant="danger" block @click="deleteCarbonSale">
              <feather-icon icon="EditIcon" class="mr-50" />
              <span class="align-middle">Eliminar Venta</span>
          </b-button>
      </b-modal>

      <b-modal size="lg" ref="saleModal" centered hide-footer title="Digite la siguiente información">
          <b-card-text>
              <b-form>                  
                  <b-row>
                      <b-col md="6" xl="6" class="mb-0">
                          <b-form-group label="Nombre Finca - Nombre Productor" label-for="basicInput">
                          <select v-model="carbonFarmID" id="dropdown">
                          <option v-for="item in carbonFarms" :key="item.carbonFarmID" :value="item.carbonFarmID">{{ item.farmName }} - {{ item.firstName }} {{ item.lastName }}</option>
                          </select>
                          </b-form-group>
                      </b-col>

                      <b-col md="6" xl="6" class="mb-1">
                          <label for="example-datepicker">Fecha de Venta</label>
                          <b-form-datepicker :show-decade-nav="true" v-model="saleDate" id="example-datepicker2" class="mb-1"/>
                      </b-col>
                  </b-row>

                  <b-row>                      
                      <b-col md="4" xl="4" class="mb-0">
                          <b-form-group label="Cantidad Créditos" label-for="basicInput">
                              <b-form-input v-model="amountCredits" placeholder="Digite" />
                          </b-form-group>
                      </b-col>

                      <b-col md="4" xl="4" class="mb-0">
                          <b-form-group label="Valor por Crédito" label-for="basicInput">
                              <b-form-input v-model="valuePerCredit" placeholder="Digite" />
                          </b-form-group>
                      </b-col>

                      <b-col md="4" xl="4" class="mb-0">
                          <b-form-group label="Costo Certificación" label-for="basicInput">
                              <b-form-input v-model="certificationCost" placeholder="Digite" />
                          </b-form-group>
                      </b-col>
                  </b-row>

                  <b-row>
                      <b-col md="4" xl="4" class="mb-0">
                          <b-form-group label="Costo Auditoría" label-for="basicInput">
                              <b-form-input v-model="auditingCost" placeholder="Digite" />
                          </b-form-group>
                      </b-col>

                      <b-col md="4" xl="4" class="mb-0">
                          <b-form-group label="Costo Transaccional" label-for="basicInput">
                              <b-form-input v-model="transactionCost" placeholder="Digite" />
                          </b-form-group>
                      </b-col>

                      <b-col md="4" xl="4" class="mb-0">
                          <b-form-group label="Costo Administrativo" label-for="basicInput">
                              <b-form-input v-model="managementCost" placeholder="Digite" />
                          </b-form-group>
                      </b-col>    
                  </b-row>

                  <b-row>
                      <b-col md="4" xl="4" class="mb-0">
                          <b-form-group label="Costo Monitoreo" label-for="basicInput">
                              <b-form-input v-model="monitoringCost" placeholder="Digite" />
                          </b-form-group>
                      </b-col>
                  </b-row>

                  <b-row class="justify-content-end">
                      <b-col v-if="!processing" class="mt-1 mb-1" md="12" lg="12">
                          <b-button variant="primary" @click="saveCarbonSale" block>
                              Guardar
                          </b-button>
                      </b-col>
                      <b-col v-if="processing" class="mt-1 mb-1" md="12" lg="12">
                          <b-button variant="primary" disabled block>
                              <b-spinner small label="Cargando..." />
                          </b-button>
                      </b-col>
                  </b-row>
              </b-form>
          </b-card-text>
      </b-modal>

  </div>
</template>

<script>

import {
  BLink, BButton, BImg, BSpinner, BRow, BCol, BFormInput, BFormTextarea, BFormGroup,
  BTable, BAlert, BBadge, BFormFile, BForm, BFormSelect, BFormCheckbox, BCardText, BFormDatepicker
} from 'bootstrap-vue'
import BCardCode from '@/@core/components/b-card-code/BCardCode.vue';
import Cleave from 'vue-cleave-component';
import axios from '@axios'
export default {
  components: {
      BAlert,
      BLink,
      BButton,
      BCardText,
      BImg,
      BSpinner,
      BBadge,
      BRow,
      BCol,
      BForm,
      BFormInput,
      BFormTextarea,
      BFormGroup,
      BFormFile,
      BTable,
      BCardCode,
      BFormSelect,
      BFormCheckbox,
      BFormDatepicker,
      Cleave,
  },
  data() {
      return {
          selectMode: 'single',
          fields: [
              { key: 'farmName', label: 'Nombre finca' },
              { key: 'productorName', label: 'Nombre productor' },
              { key: 'saleDate', label: 'Fecha Venta' },
              { key: 'amountCredits', label: 'Cantidad de Créditos' },
              { key: 'valuePerCredit', label: 'Valor por crédito' },
              { key: 'certificationCost', label: 'Costo Certificación' },
              { key: 'auditingCost', label: 'Costo Auditoría' },
              { key: 'transactionCost', label: 'Costo Transaccional' },
              { key: 'managementCost', label: 'Costo Administrativo' },
              { key: 'monitoringCost', label: 'Costo Monitoreo' },
          ], 
          carbonSales: [{}],
          carbonFarms: [{}],
          farmName: null,
          productorName: null,
          firstName: null,
          lastName: null,
          selectedCarbonSale: {},
          amountCredits: null,
          valuePerCredit: null,
          certificationCost: null,
          auditingCost: null,
          transactionCost: null,
          managementCost: null,
          monitoringCost: null,
          saleDate: null,
          carbonSaleID: null,
          carbonFarmID: null,
          processing: null,
      }
  },
  created() {
      this.getAllCarbonSales()
      this.getAllCarbonFarms()
  },
  methods: {
    getAllCarbonSales() {
          axios.get('/CarbonSale/getAllCarbonSales', {
              params: {
                farmName: this.farmName, firstName: this.firstName, lastName: this.lastName
              }
          })
              .then(response => {
                  this.carbonSales = response.data;
              })
    },
    
    getAllCarbonFarms() {
        axios.get('/CarbonFarm/GetAllCarbonFarms', {
            params: {
            }
        })
            .then(response => {
                this.carbonFarms = response.data;
            })
    },
      
    onRowSelected(items) {        
      this.selectedCarbonSale = items[0];
      if(this.selectedCarbonSale) this.$refs['buttons-carbon-sale-modal'].show()
    },

    addNewSale() {
          this.processing = false;
          this.carbonSaleID = undefined;
          this.saleDate = null,
          this.amountCredits = null,
          this.valuePerCredit= null,
          this.certificationCost = null,
          this.auditingCost = null,
          this.transactionCost = null,
          this.managementCost = null,
          this.monitoringCost = null, 
          this.$refs['saleModal'].show()
    },
    
    saveCarbonSale() {
          this.processing = true;
          var payload = {
              carbonSaleID: this.carbonSaleID,
              carbonFarmID: this.carbonFarmID,
              saleDate: this.saleDate,
              amountCredits: Number(this.amountCredits),
              valuePerCredit: Number(this.valuePerCredit),
              certificationCost: Number(this.certificationCost),               
              auditingCost: Number(this.auditingCost),
              transactionCost: Number(this.transactionCost),
              managementCost: Number(this.managementCost),
              monitoringCost: Number(this.monitoringCost)
          }
          
          axios.post('/CarbonSale/SaveCarbonSale', payload)
              .then(response => {
                  this.processing = false;
                  this.$refs['buttons-carbon-sale-modal'].hide();
                  this.$refs['saleModal'].hide();
                  this.$bvToast.toast("La información ha sido guardada", { title: 'Ok', variant: 'success', solid: true })

                  this.getAllCarbonSales();

              })
              .catch(error => {
                  this.processing = false;
                  if (error.response) {
                      this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
                  } else {
                      this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
                  }
              })
    },
    
    editCarbonSale() {        
      this.carbonSaleID = this.selectedCarbonSale.carbonSaleID;
      this.carbonFarmID = this.selectedCarbonSale.carbonFarmID;
      this.saleDate = this.selectedCarbonSale.saleDate;
      this.amountCredits = this.selectedCarbonSale.amountCredits;
      this.valuePerCredit = this.selectedCarbonSale.valuePerCredit;
      this.certificationCost = this.selectedCarbonSale.certificationCost;
      this.auditingCost = this.selectedCarbonSale.auditingCost;
      this.transactionCost = this.selectedCarbonSale.transactionCost;
      this.managementCost = this.selectedCarbonSale.managementCost;        
      this.monitoringCost = this.selectedCarbonSale.monitoringCost;
      
      this.getAllCarbonSales();
      this.$refs['saleModal'].show()
    },
      
    deleteCarbonSale(){            
          axios.get('/CarbonSale/DeleteCarbonSale', { params: { carbonSaleID: this.selectedCarbonSale.carbonSaleID }})
          .then(response => {
              this.$refs['buttons-carbon-sale-modal'].hide();
              window.location.reload()
          })
          .catch(error => {
              this.processing = false;
              if(error.response){
                  this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
              } else {
                  this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
              }
          })
    }
  }
}

</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>